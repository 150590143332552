import Homepage from './Homepage';

function App() {

  return (
      <Homepage />
  );
}

export default App;
